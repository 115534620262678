<template>
  <div>
    <p class="text-subhead-3 mb-1">New message</p>
    <div class="text-body-2 text-black-80">
      <button class="text-subhead-4" @click="goToLead">{{ lead_name }}</button> sent you message in WhatsApp.
    </div>
  </div>
</template>

<script setup lang="ts">
const emits = defineEmits(['close'])
type Props = {
  // eslint-disable-next-line vue/prop-name-casing
  lead_id: number
  // eslint-disable-next-line vue/prop-name-casing
  lead_name: string
}

const props = defineProps<Props>()

const goToLead = () => {
  navigateTo(`/leads/${props.lead_id}#whatsapp`)
  emits('close')
}
</script>

<style scoped></style>
