<template>
  <div class="relative flex min-h-screen flex-col">
    <Header />
    <main class="flex flex-1 flex-col sm:pt-[72px]">
      <slot />
    </main>
    <UiSnackBanner />
    <UiToast />
  </div>
</template>

<script setup lang="ts"></script>

<style scoped></style>
